<template>
  <Blocker v-if="$store.state.loading > 0" />
  <div v-show="!activated">Not found</div>
  <nav class="navbar navbar-light bg-light">
    <div class="container">
      <span class="navbar-brand">
        <img
          :src="$store.state.logo"
          style="max-height: 50px; max-width: 200px"
        />
      </span>
      <span class="d-flex">
        <!--
        //REMOVAL ML SERVER
        <label
          for="file-upload"
          class="custom-file-upload btn btn-link"
          v-if="!$store.state.showMailForm && !$store.state.showAR"
        >
          <i class="fa fa-camera"></i><br />
        </label>
        <input id="file-upload" type="file" accept="image/*;capture=camera" />
        //-->
        <AddToFav
          :currentTile="$store.state.paramTile"
          v-show="!$store.state.showMailForm"
        />
        <button
          class="btn btn-link"
          v-on:click="toggleMailForm"
          v-if="!$store.state.showAR"
        >
          <i class="fas fa-envelope" v-show="!$store.state.showMailForm"></i>
          <i class="fas fa-times" v-show="$store.state.showMailForm"></i>
        </button>
        <button
          class="btn btn-link"
          v-on:click="toggleAR"
          v-if="!$store.state.showMailForm"
        >
          <i class="fas fa-vr-cardboard" v-show="!$store.state.showAR"></i>
          <i class="fas fa-times" v-show="$store.state.showAR"></i>
        </button>
      </span>
    </div>
  </nav>
  <MailForm v-if="$store.state.showMailForm" />
  <div v-show="activated && !$store.state.showMailForm">
    <SettingsForm />
  </div>
  <div v-show="activated && !$store.state.showMailForm">
    <ProductForm />
  </div>
  <div
    class="home"
    v-show="activated && !$store.state.showMailForm && !$store.state.showAR"
  >
    <!--<h1 class="mt-2">{{ $store.state.paramTile }}</h1>//-->
    <OutputImage />
    <MLServer />
  </div>

  <div style="height: 100%" v-if="activated && $store.state.showAR">
    <ARViewer />
  </div>
</template>

<script>
// @ is an alias to /src
import AddToFav from "@/components/AddToFav.vue";
import MailForm from "@/components/MailForm.vue";
import SettingsForm from "@/components/SettingsForm.vue";
import ProductForm from "@/components/ProductForm.vue";
import OutputImage from "@/components/OutputImage.vue";
import MLServer from "@/components/MLServer.vue";
import ARViewer from "@/components/ARViewer.vue";
import { getFirestore, getDoc, doc } from "firebase/firestore";

export default {
  name: "Home",
  components: {
    AddToFav,
    MailForm,
    SettingsForm,
    ProductForm,
    OutputImage,
    MLServer,
    ARViewer,
  },
  data() {
    return {
      version: "1.1.3",
      customerID: "",
      activated: false,
    };
  },
  mounted: function () {
    this.customerID = this.$route.params.id || "SsonBpeIIcP0I43Oa8jerOqUPeM2";
    this.$store.commit("setUID", this.customerID);
    this.$store.commit("initialiseStore", this.customerID);
    this.$store.commit("setLoading", 1);
    this.$store.commit("setLoadTile", 1);
    this.$store.commit("setLoadTiles", true);
    this.$i18n.locale = this.$route.query.lang || "en";
    //the Tile
    this.$store.commit("setParamTile", this.$route.query.tile || "T02");
    //end define tile
    this.loadApp();
    this.$store.commit("setLoading", -1);
  },
  methods: {
    loadApp: async function () {
      this.$store.commit("setLoading", 1);
      this.activated = await this.getLicenseInfo(this.customerID);
      if (this.activated) {
        console.log("Activated");
      } else {
        console.log("No license");
      }
      this.$store.commit("setLoading", -1);
    },
    toggleMailForm: function () {
      this.$store.commit("toggleMailForm");
    },
    toggleAR: function () {
      this.$store.commit("toggleAR");
    },
    async getLicenseInfo(uid) {
      const db = getFirestore();
      const docSnap = await getDoc(doc(db, "users", uid));
      if (docSnap.exists()) {
        const info = docSnap.data();
        const status = info.status || "deleted";
        const endDate = info.cancellation_effective_date || "";
        this.$store.commit("setLogo", info);
        if (endDate != "") {
          const d = new Date(endDate);
          if (
            status == "active" ||
            status == "trialing" ||
            status == "infinite" ||
            (status == "deleted" && d >= new Date())
          ) {
            return true;
          }
        } else {
          if (
            status == "active" ||
            status == "trialing" ||
            status == "infinite"
          )
            return true;
        }
      }
      return false;
    },
  },
};
</script>
