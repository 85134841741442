<template>
  <button class="btn btn-link" v-on:click="toggleFav">
    <i class="fa-heart" :class="[inFavs ? 'fas' : 'far']"></i>
  </button>
</template>
<script>
export default {
  name: "AddToFav",
  props: ["currentTile"],
  computed: {
    inFavs: {
      get() {
        return this.$store.state.fav.includes(this.currentTile);
      },
    },
  },
  methods: {
    toggleFav() {
      if (this.$store.state.fav.includes(this.currentTile)) {
        //remove
        this.$store.commit("removeFromFav", this.currentTile);
      } else {
        //add
        this.$store.commit("addToFav", this.currentTile);
      }
    },
  },
};
</script>
