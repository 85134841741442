<template>
  <model-viewer
    id="viewer"
    alt="TileAR"
    src=""
    ar
    ar-modes="webxr scene-viewer quick-look"
    ar-scale="auto"
    ar-placement="floor"
    camera-controls
    style="margin: 0px; height: 100%; width: 100%"
  >
    <button slot="ar-button" class="arButton" id="arButton">
      <span class="material-icons fs-1">view_in_ar</span><br />
      <span class="small">{{ $t("view-in-ar") }}</span>
    </button>
    <div id="warning">{{ $t("ARWarning") }}</div>
    <div id="error" class="hide">{{ $t("ARError") }}</div>
  </model-viewer>
  <!-- Floor Depth //-->
  <div class="form-group mb-3 floorDepth">
    <label for="customRange1" class="form-label"
      >{{ $t("floor-depth") }} ({{ paramFloorY }} m)</label
    >
    <input
      type="range"
      class="form-range"
      id="customRange1"
      v-model="paramFloorY"
      v-on:change="buildModel()"
      min="1"
      max="10"
      step="1"
    />
  </div>
  <!-- Floor Width //-->
  <div class="form-group mb-3 floorWidth">
    <label for="customRange1" class="form-label"
      >{{ $t("floor-width") }} ({{ paramFloorX }} m)</label
    >
    <input
      type="range"
      class="form-range"
      id="customRange1"
      v-model="paramFloorX"
      v-on:change="buildModel()"
      min="1"
      max="10"
      step="1"
    />
  </div>
  <a-scene style="display: none">
    <a-assets>
      <canvas
        id="mat_main"
        crossorigin="anonymous"
        width="1000px"
        height="1000px"
      ></canvas>
      <canvas
        id="mat_restX"
        crossorigin="anonymous"
        width="1000px"
        height="1000px"
      ></canvas>
      <canvas
        id="mat_restY"
        crossorigin="anonymous"
        width="1000px"
        height="1000px"
      ></canvas>
      <canvas
        id="mat_rest"
        crossorigin="anonymous"
        width="1000px"
        height="1000px"
      ></canvas>
    </a-assets>
    <a-entity tilecontainer />
  </a-scene>
</template>

<script>
// @ is an alias to /src
import "@google/model-viewer";
//import GLTFExporter from "../assets/js/GLTFExporter.js";

export default {
  name: "ARViewer",
  components: {},
  data() {
    return {
      paramFloorX: 2,
      paramFloorY: 2,
      // eslint-disable-next-line no-undef
      gltfExporter: new THREE.GLTFExporter(),
      //width
      totalTileWidth: 0,
      tilesPer2000X: 0,
      plateWidth: 0,
      plateXNeeded: 0,
      plateXRest: 0,
      //Depth
      totalTileDepth: 0,
      tilesPer2000Y: 0,
      plateDepth: 0,
      plateYNeeded: 0,
      plateYRest: 0,
    };
  },
  watch: {
    "$store.state.redraw": function () {
      this.buildModel();
    },
  },
  computed: {
    floorX: {
      get() {
        return this.paramFloorX * 1000;
      },
      set(newValue) {
        this.paramFloorX = newValue;
      },
    },
    floorY: {
      get() {
        return this.paramFloorY * 1000;
      },
      set(newValue) {
        this.paramFloorY = newValue;
      },
    },
  },
  mounted: function () {
    this.tileContainer =
      document.querySelector("[tilecontainer]").components.tilecontainer;

    /*document.querySelector("#viewer").addEventListener("load", () => {
      console.log("BUILDING");
      this.buildModel();
      this.$store.commit("setLoading", -1);
    });*/
    //texture loader
    this.img = document.getElementById("theTile");
    this.buildModel();
    document.querySelector("#viewer").addEventListener("load", () => {
      const error = document.querySelector("#error");
      if (!document.getElementById("viewer").canActivateAR) {
        error.classList.remove("hide");
        error.addEventListener("transitionend", () => {
          error.classList.add("hide");
        });
      } else {
        error.classList.add("hide");
      }
    });
  },
  methods: {
    buildModel: function () {
      this.$store.commit("setLoading", 1);
      document.querySelector("[tilecontainer]").innerHTML = "";
      switch (this.$store.state.paramPatern) {
        case "Brickwork":
          this.calculate(true);
          this.Brickwork();
          break;
        case "Checkers":
          this.calculate();
          this.Checkers();
          break;
        case "Seamless":
          this.calculate();
          this.Grid();
          break;
        default:
          this.calculate();
          this.Grid();
      }
      this.buildPlates();
      var self = this;
      //Use set Timeout to give the building of the plates some time before exporting as glft
      window.setTimeout(() => {
        //Use the glftExporter to export the Aframe image to glft and use this new model as input for the modelviewer component
        self.gltfExporter.parse(
          // eslint-disable-next-line no-undef
          AFRAME.scenes[0].object3D,
          function (result) {
            var output = JSON.stringify(result, null, 2);
            var file = new Blob([output], { type: "application/json" });
            var modelViewer = document.querySelector("#viewer");
            modelViewer.src = URL.createObjectURL(file);
            self.$store.commit("setLoading", -1);
          },
          function (error) {
            console.log(error);
            this.$store.commit("setLoading", -1);
          },
          { binary: false }
        );
      }, 1000);
    },
    //Create the material context and fill with the grout color
    //TODO: Clear the material first?
    get_ctx: function (material, width, height) {
      var mat = document.getElementById(material);
      if (width != 0 && height != 0) {
        mat.height = height;
        mat.width = width;
      } else {
        mat.height = 10;
        mat.width = 10;
      }
      var ctx = mat.getContext("2d");
      ctx.fillStyle =
        this.$store.state.groutColor[this.$store.state.paramGroutColor];
      ctx.fillRect(0, 0, mat.width, mat.height);
      return ctx;
    },
    //Render a Grid patern.
    Grid: function () {
      let realWidth = this.img.naturalWidth;
      let realHeight = this.img.naturalHeight;

      var ctx_matMain = this.get_ctx(
        "mat_main",
        this.plateWidth,
        this.plateDepth
      );

      var ctx_matRestX = this.get_ctx(
        "mat_restX",
        this.plateXRest,
        this.plateDepth
      );

      var ctx_matRestY = this.get_ctx(
        "mat_restY",
        this.plateWidth,
        this.plateYRest
      );
      var ctx_matRest = this.get_ctx(
        "mat_rest",
        this.plateXRest,
        this.plateYRest
      );

      for (var i = 0; i < this.tilesPer2000X; i++) {
        var xPos =
          this.$store.state.paramTileWidth * i +
          this.$store.state.paramGrout * i;
        for (var y = 0; y < this.tilesPer2000Y; y++) {
          var yPos =
            this.$store.state.paramTileDepth * y +
            this.$store.state.paramGrout * y;
          ctx_matMain.drawImage(
            this.img,
            0,
            0,
            realWidth,
            realHeight,
            xPos,
            yPos,
            this.$store.state.paramTileWidth,
            this.$store.state.paramTileDepth
          );
          if (this.plateYRest != 0) {
            ctx_matRestY.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              this.$store.state.paramTileWidth,
              this.$store.state.paramTileDepth
            );
          }
          if (this.plateXRest != 0) {
            ctx_matRestX.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              this.$store.state.paramTileWidth,
              this.$store.state.paramTileDepth
            );
          }
          if (this.plateXRest != 0 && this.plateYRest != 0) {
            ctx_matRest.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              this.$store.state.paramTileWidth,
              this.$store.state.paramTileDepth
            );
          }
        }
      }
    },
    // Render a brickwork patern
    Brickwork: function () {
      let realWidth = this.img.naturalWidth;
      let realHeight = this.img.naturalHeight;

      var ctx_matMain = this.get_ctx(
        "mat_main",
        this.plateWidth,
        this.plateDepth
      );

      var ctx_matRestX = this.get_ctx(
        "mat_restX",
        this.plateXRest,
        this.plateDepth
      );

      var ctx_matRestY = this.get_ctx(
        "mat_restY",
        this.plateWidth,
        this.plateYRest
      );
      var ctx_matRest = this.get_ctx(
        "mat_rest",
        this.plateXRest,
        this.plateYRest
      );

      for (var i = 0; i < this.tilesPer2000X + 1; i++) {
        var shift = false;
        var xPos =
          this.$store.state.paramTileWidth * i +
          this.$store.state.paramGrout * i;
        xPos = xPos - this.$store.state.paramTileWidth / 2;
        for (var y = 0; y < this.tilesPer2000Y; y++) {
          if (shift) {
            xPos = xPos + this.$store.state.paramTileWidth / 2;
          } else {
            xPos =
              this.$store.state.paramTileWidth * i +
              this.$store.state.paramGrout * i;
            xPos = xPos - this.$store.state.paramTileWidth / 2;
          }
          var yPos =
            this.$store.state.paramTileDepth * y +
            Math.ceil(this.$store.state.paramGrout) * y;
          ctx_matMain.drawImage(
            this.img,
            0,
            0,
            realWidth,
            realHeight,
            xPos,
            yPos,
            this.$store.state.paramTileWidth,
            this.$store.state.paramTileDepth
          );
          if (this.plateYRest != 0) {
            ctx_matRestY.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              this.$store.state.paramTileWidth,
              this.$store.state.paramTileDepth
            );
          }
          if (this.plateXRest != 0) {
            ctx_matRestX.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              this.$store.state.paramTileWidth,
              this.$store.state.paramTileDepth
            );
          }
          if (this.plateXRest != 0 && this.plateYRest != 0) {
            ctx_matRest.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              this.$store.state.paramTileWidth,
              this.$store.state.paramTileDepth
            );
          }
          shift = !shift;
        }
      }
    },
    //Render a checkers patern
    //TODO: No implementation yet.
    Checkers() {},
    //Calculate the number of plates and texture to create to avoid canvas limits.
    calculate: function (makeEven) {
      //TODO: Find out why we need to make it even
      makeEven = makeEven || false;
      //width
      this.totalTileWidth =
        this.$store.state.paramTileWidth + this.$store.state.paramGrout; //example 600mm + 3mm = 603
      this.tilesPer2000X = Math.floor(2000 / this.totalTileWidth); // example 2000 / 603 = 3.xxxx -> floor = 3
      this.plateWidth = this.totalTileWidth * this.tilesPer2000X; // 603 * 3 = 1809
      this.plateXNeeded = Math.floor(this.floorX / this.plateWidth); // 10000 / 1809 = 5.xxxx -> floor = 5
      this.plateXRest = this.floorX - this.plateWidth * this.plateXNeeded; // 10000 - 9045 = 955

      //Depth
      this.totalTileDepth =
        this.$store.state.paramTileDepth + this.$store.state.paramGrout;
      this.tilesPer2000Y = Math.floor(2000 / this.totalTileDepth);
      //make it even / why???
      if (makeEven) {
        this.tilesPer2000Y =
          this.tilesPer2000Y % 2 == 0
            ? this.tilesPer2000Y
            : this.tilesPer2000Y - 1;
      }
      this.plateDepth = this.totalTileDepth * this.tilesPer2000Y;
      this.plateYNeeded = Math.floor(this.floorY / this.plateDepth);
      this.plateYRest = this.floorY - this.plateDepth * this.plateYNeeded;
    },
    // Build the all the needed 3D plates
    buildPlates: function () {
      var xPos = 0;
      var yPos = 0;
      for (var pI = 0; pI < this.plateXNeeded; pI++) {
        xPos = (this.plateWidth / 1000) * pI;
        for (var pY = 0; pY < this.plateYNeeded; pY++) {
          yPos = (this.plateDepth / 1000) * pY;
          this.tileContainer.appendEntity(
            this.createTile(
              xPos,
              yPos,
              this.plateWidth / 1000,
              this.plateDepth / 1000,
              "mat_main"
            )
          );
        }
      }

      for (var rx = 0; rx < this.plateYNeeded; rx++) {
        yPos = (this.plateDepth / 1000) * rx;
        xPos =
          this.plateWidth / 1000 / 2 +
          this.plateXRest / 1000 / 2 +
          (this.plateWidth / 1000) * (this.plateXNeeded - 1);
        this.tileContainer.appendEntity(
          this.createTile(
            xPos,
            yPos,
            this.plateXRest / 1000,
            this.plateDepth / 1000,
            "mat_restX"
          )
        );
      }

      for (var ry = 0; ry < this.plateXNeeded; ry++) {
        yPos =
          this.plateDepth / 1000 / 2 +
          this.plateYRest / 1000 / 2 +
          (this.plateDepth / 1000) * (this.plateYNeeded - 1);
        xPos = (this.plateWidth / 1000) * ry;
        this.tileContainer.appendEntity(
          this.createTile(
            xPos,
            yPos,
            this.plateWidth / 1000,
            this.plateYRest / 1000,
            "mat_restY"
          )
        );
      }

      if (this.plateXRest != 0 && this.plateYRest != 0) {
        xPos =
          this.plateWidth / 1000 / 2 +
          this.plateXRest / 1000 / 2 +
          (this.plateWidth / 1000) * (this.plateXNeeded - 1);

        yPos =
          this.plateDepth / 1000 / 2 +
          this.plateYRest / 1000 / 2 +
          (this.plateDepth / 1000) * (this.plateYNeeded - 1);

        this.tileContainer.appendEntity(
          this.createTile(
            xPos,
            yPos,
            this.plateXRest / 1000,
            this.plateYRest / 1000,
            "mat_rest"
          )
        );
      }
    },
    createTile: function (x, y, w, h, mat) {
      mat = mat || "mat_main";
      var entity = document.createElement("a-box");
      entity.setAttribute("material", {
        src: "#" + mat,
      });
      entity.setAttribute("position", {
        x: x, // X axis
        y: 0, // Elevation axis
        z: y, // Y axis
      });
      entity.setAttribute("width", w);
      entity.setAttribute("height", 0.01);
      entity.setAttribute("depth", h);
      return entity;
    },
  },
};
</script>
