<template>
  <div class="text-center mb-0 pb-0">
    <h6 class="m-0 p-0">{{ $t("currentTile") }}</h6>
    <p class="m-0 p-0">
      {{ $store.state.paramTile }}
    </p>
  </div>
  <div class="row g-0 align-items-center">
    <div class="col-2 text-start"></div>
    <div class="col-8">
      <div class="row g-1">
        <div class="col-4"></div>
        <div class="col-4">
          <button
            class="btn btn-link p-0"
            @click="$emit('changeTile', $store.state.paramTile)"
          >
            <img class="img-fluid" :src="$store.state.paramPicture" />
          </button>
        </div>
        <div class="col-4"></div>
      </div>
    </div>
    <div class="col-2 text-end"></div>
  </div>

  <div class="row g-0 align-items-center">
    <div class="col-2 text-start">
      <button class="btn btn-primary" v-if="showPrev" v-on:click="onPrevClick">
        <i class="fas fa-angle-left"></i>
      </button>
    </div>
    <div class="col-8">
      <div class="row g-1 align-items-center">
        <div class="col-4" v-for="item in tileList" :key="item.id">
          <button
            class="btn btn-link p-0"
            @click="$emit('changeTile', item.id)"
          >
            <img class="img-fluid" :src="item.picture" />
          </button>
        </div>
      </div>
    </div>
    <div class="col-2 text-end">
      <button class="btn btn-primary" v-if="showNext" v-on:click="onNextClick">
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
import {
  getFirestore,
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from "firebase/firestore";

export default {
  name: "TileSelect",
  /*props: {
    loadTiles: {
      type: Boolean,
      default: false,
    },
  },*/
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 3,
      startAfter: false,
      tileList: [], //config
      tileBufferList: [],
      showPrev: false,
      showNext: false,
    };
  },
  watch: {
    "$store.state.loadTiles": function () {
      if (this.tileList.length == 0) this.listTiles(25);
    },
  },
  /*watch: {
    loadTiles() {
      if (this.tileList.length == 0) this.listTiles(25);
    },
  },*/
  methods: {
    paginate(items, page = 1, perPage = 10) {
      const offset = perPage * (page - 1);
      const totalPages = Math.ceil(items.length / perPage);
      const paginatedItems = items.slice(offset, perPage * page);

      return {
        previousPage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
        items: paginatedItems,
      };
    },
    setTileList() {
      const paginated = this.paginate(
        this.tileBufferList,
        this.currentPage,
        this.itemsPerPage
      );
      this.tileList = paginated.items;
      this.showNext = paginated.nextPage > 1 ? true : false;
      this.showPrev = paginated.previousPage != null ? true : false;
      //load more tiles
      if (this.currentPage == paginated.totalPages - 2) this.nextTiles(25);
    },
    async listTiles(p_limit) {
      this.tileList = [];
      p_limit = p_limit || 25;
      const db = getFirestore();
      // Query the first page of docs
      const first = query(
        collection(db, "users", this.$store.state.uid, "tiles"),
        orderBy("id"),
        limit(p_limit)
      );
      const documentSnapshots = await getDocs(first);
      documentSnapshots.forEach((doc) => {
        this.tileBufferList.push(doc.data());
      });

      if (documentSnapshots.docs.length == p_limit) {
        this.startAfter =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      } else {
        this.startAfter = false;
      }
      this.setTileList();
    },
    onPrevClick() {
      this.currentPage--;
      this.setTileList();
    },
    onNextClick() {
      this.currentPage++;
      this.setTileList();
    },
    async nextTiles(p_limit) {
      if (this.startAfter) {
        console.log("gettingMore");
        const db = getFirestore();
        const next = query(
          collection(db, "users", this.$store.state.uid, "tiles"),
          orderBy("id"),
          startAfter(this.startAfter),
          limit(p_limit)
        );
        const documentSnapshots = await getDocs(next);
        documentSnapshots.forEach((doc) => {
          this.tileBufferList.push(doc.data());
        });

        if (documentSnapshots.docs.length == p_limit) {
          this.startAfter =
            documentSnapshots.docs[documentSnapshots.docs.length - 1];
        } else {
          this.startAfter = false;
        }
      }
    },
  },
};
</script>
