<template>
  <canvas id="inputImage" class="fake-hide"></canvas>
  <canvas id="mask" class="fake-hide"></canvas>
  <canvas id="floor" class="fake-hide"></canvas>
  <canvas id="perspective" class="fake-hide"></canvas>
  <canvas id="outputImage" class="fake-hide"></canvas>
  <img id="displayImage" class="displayImage" />
  <img id="theTile" class="fake-hide" />
</template>

<script>
import cv from "@techstark/opencv-js";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import canvasSize from "canvas-size";

export default {
  name: "OutputImage",
  data() {
    return {
      isValidCanvas: false,
    };
  },
  mounted: function () {
    this.isValidCanvas = canvasSize.test({
      width: 2048,
      height: 2048,
    });

    this.$store.commit("setLoading", 1);
    this.displayImage = document.getElementById("displayImage");
    this.inputImage = document.getElementById("inputImage");
    this.floor = document.getElementById("floor");
    this.ctx_floor = this.floor.getContext("2d");
    this.perspective = document.getElementById("perspective");
    this.mask = document.getElementById("mask");
    this.outputImage = document.getElementById("outputImage");
    this.ctx_outputImage = this.outputImage.getContext("2d");
    this.img = document.getElementById("theTile");
    this.img.crossOrigin = "anonymous";
    this.img.addEventListener("load", () => {
      console.log("Loaded Tile");
      this.$store.commit("redraw", 1);
    });
    this.$store.commit("setLoading", -1);
  },
  watch: {
    "$store.state.loadTile": function () {
      this.loadTile();
    },
    "$store.state.redraw": function () {
      if (this.isValidCanvas) this.buildFloor(2048);
      else {
        if (
          canvasSize.test({
            width: 1024,
            height: 1024,
          })
        )
          this.buildFloor(1024);
        else alert(this.$t("notCompatible"));
      }
      this.buildRender();
      console.log("render");
    },
  },
  methods: {
    buildRender() {
      this.$store.commit("setLoading", 1);
      this.calcPerspective();
      this.outputImage.width = this.inputImage.width;
      this.outputImage.height = this.inputImage.height;
      this.ctx_outputImage.globalCompositeOperation = "copy";
      this.ctx_outputImage.drawImage(
        this.mask,
        0,
        0,
        this.outputImage.width,
        this.outputImage.height
      );
      this.ctx_outputImage.globalCompositeOperation = "xor";
      this.ctx_outputImage.imageSmoothingEnabled = true;
      this.ctx_outputImage.drawImage(this.inputImage, 0, 0);
      this.ctx_outputImage.globalCompositeOperation = "destination-over";
      this.ctx_outputImage.drawImage(this.perspective, 0, 0, 1024, 1024);
      this.ctx_outputImage.globalCompositeOperation = "source-over";
      this.displayImage.src = this.outputImage.toDataURL();
      if (this.outputImage.width > this.outputImage.height) {
        //land scape image
        if (window.innerWidth < window.innerHeight - 100) {
          this.displayImage.style.width = "100%";
          this.displayImage.style.height = null;
        } else {
          this.displayImage.style.width = null;
          this.displayImage.style.height =
            (window.innerHeight - 100).toString() + "px";
        }
      } else {
        //Portrait images
        if (window.innerWidth < window.innerHeight - 100) {
          const scaledHeight =
            (this.outputImage.height / this.outputImage.width) *
            window.innerWidth;
          if (scaledHeight <= window.innerHeight - 100) {
            this.displayImage.style.width = "100%";
            this.displayImage.style.height = null;
          } else {
            this.displayImage.style.width = null;
            this.displayImage.style.height =
              (window.innerHeight - 100).toString() + "px";
          }
        } else {
          this.displayImage.style.width = null;
          this.displayImage.style.height =
            (window.innerHeight - 100).toString() + "px";
        }
      }
      this.$store.commit("setLoading", -1);
    },
    calcPerspective() {
      const size = this.floor.width;
      const perspectiveY =
        size / 2 +
        (this.$store.state.topPixelOfMask < size / 4
          ? this.$store.state.topPixelOfMask
          : size / 4);
      //const perspectiveY = size / 2;
      let src = cv.imread("floor");
      let dst = new cv.Mat();
      let dsize = new cv.Size(src.rows, src.cols);
      let srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, [
        0,
        0,
        size,
        0,
        0,
        size,
        size,
        size,
      ]);
      let dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, [
        0 + size / 4,
        perspectiveY,
        size - size / 4,
        perspectiveY,
        0,
        size,
        size,
        size,
      ]);
      let M = cv.getPerspectiveTransform(srcTri, dstTri);
      // You can try more different parameters
      cv.warpPerspective(
        src,
        dst,
        M,
        dsize,
        cv.INTER_LINEAR,
        cv.BORDER_CONSTANT,
        new cv.Scalar()
      );
      let rect = new cv.Rect(size / 4, size / 2, size / 2, size / 2);
      dst = dst.roi(rect);
      cv.imshow("perspective", dst);
      src.delete();
      dst.delete();
      M.delete();
      srcTri.delete();
      dstTri.delete();
    },
    buildFloor(size) {
      const realWidth = this.img.naturalWidth;
      const realHeight = this.img.naturalHeight;

      let floorWidth = Math.ceil(this.$store.state.paramTileWidth / 2);
      let floorDepth = Math.ceil(this.$store.state.paramTileDepth / 2);
      if (!this.isValidCanvas) {
        floorWidth = Math.ceil(this.$store.state.paramTileWidth / 4);
        floorDepth = Math.ceil(this.$store.state.paramTileDepth / 4);
      }
      const grout = Math.ceil(this.$store.state.paramGrout / 2);
      this.floor.width = size;
      this.floor.height = size;
      const imgWidth = Math.sqrt(
        Math.pow(this.floor.width, 2) + Math.pow(this.floor.height, 2)
      );
      const imgHeight = Math.sqrt(
        Math.pow(this.floor.width, 2) + Math.pow(this.floor.height, 2)
      );
      // Grout color
      this.ctx_floor.fillStyle =
        this.$store.state.groutColor[this.$store.state.paramGroutColor];
      this.ctx_floor.fillRect(0, 0, this.floor.width, this.floor.height);

      let xPos = 0;
      let yPos = (imgHeight / 2) * -1;
      this.ctx_floor.rotate((45 * Math.PI) / 180);
      if (this.$store.state.paramPatern != "Brickwork") {
        while (xPos < imgWidth) {
          while (yPos < imgHeight) {
            this.ctx_floor.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPos,
              yPos,
              floorWidth,
              floorDepth
            );
            yPos += floorDepth + grout;
          }
          yPos = (imgHeight / 2) * -1;
          xPos += floorWidth + grout;
        }
        //this.ctx_floor.setTransform(1, 0, 0, 1, 0, 0);
      } else {
        xPos -= floorWidth + grout;
        let shift = false;
        while (xPos < imgWidth) {
          while (yPos < imgHeight) {
            let xPoss = xPos;
            if (shift) xPoss += (floorWidth + grout) / 2;
            this.ctx_floor.drawImage(
              this.img,
              0,
              0,
              realWidth,
              realHeight,
              xPoss,
              yPos,
              floorWidth,
              floorDepth
            );
            yPos += floorDepth + grout;
            shift = !shift;
          }
          shift = false;
          yPos = (imgHeight / 2) * -1;
          xPos += floorWidth + grout;
        }
      }
      console.log("Floor Ready");
    },
    loadTile: async function () {
      this.$store.commit("setLoading", 1);
      const db = getFirestore();
      const docSnap = await getDoc(
        doc(
          db,
          "users",
          this.$store.state.uid,
          "tiles",
          this.$store.state.paramTile
        )
      );
      if (docSnap.exists()) {
        const info = docSnap.data();
        this.$store.commit("setParamTileDim", {
          width: info.tileWidth,
          depth: info.tileDepth,
        });
        this.$store.commit("setParamGrout", info.grout || 3);
        this.$store.commit("setParamGroutColor", info.groutColor || "Dark");
        this.$store.commit("setParamPatern", info.patern || "Grid");
        this.$store.commit("setParamPicture", info.picture);
        this.img.src = info.picture;
      } else {
        console.log("No such document!");
        this.$store.commit("setParamTileDim", {
          width: 600,
          depth: 600,
        });
        this.$store.commit("setParamGrout", 3);
        this.$store.commit("setParamGroutColor", "Dark");
        this.$store.commit("setParamPatern", "Grid");
        this.$store.commit(
          "setParamPicture",
          "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/TileImages%2FSsonBpeIIcP0I43Oa8jerOqUPeM2%2FT01.jpg?alt=media&token=834c09b7-0036-4087-b919-b47498f0a0dc"
        );

        this.img.src =
          "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/TileImages%2FSsonBpeIIcP0I43Oa8jerOqUPeM2%2FT01.jpg?alt=media&token=834c09b7-0036-4087-b919-b47498f0a0dc";
      }
      this.$store.commit("setLoading", -1);
    },
  },
};
</script>
