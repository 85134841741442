<template>
  <div style="height: 100%; overflow: scroll; padding-bottom: 100px">
    <div class="container text-start" v-if="formSended">
      <p class="my-5">{{ $t("contact.tnx") }}</p>
    </div>
    <div class="container text-start" v-if="!formSended">
      <h1 class="mt-3">{{ $t("contact.title") }}</h1>
      <form
        class="row g-3 needs-validation"
        novalidate=""
        id="contactForm"
        data-hs-cf-bound="true"
      >
        <!-- name -->
        <div class="col-md-6">
          <label for="firstname" class="form-label">
            {{ $t("contact.firstname") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="firstname"
            name="firstname"
            value=""
            placeholder="Patrick"
            required=""
          />
          <div class="invalid-feedback">
            {{ $t("contact.firstnameError") }}
          </div>
        </div>
        <!-- name -->
        <!-- famname -->
        <div class="col-md-6">
          <label for="lastname" class="form-label">
            {{ $t("contact.lastname") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="lastname"
            name="lastname"
            value=""
            placeholder="Smith"
          />
        </div>
        <!-- famname -->
        <!-- mail -->
        <div class="col-md-6">
          <label for="email" class="form-label">
            {{ $t("contact.email") }}
          </label>
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            value=""
            placeholder="info@yourcompany.com"
            required=""
          />
          <div class="invalid-feedback">
            {{ $t("contact.emailError") }}
          </div>
        </div>
        <!-- mail -->
        <!-- Phone -->
        <div class="col-md-6">
          <label for="phone" class="form-label">{{
            $t("contact.phone")
          }}</label>
          <input
            type="phone"
            class="form-control"
            id="phone"
            name="phone"
            value=""
            placeholder=""
          />
        </div>
        <!-- phone -->
        <!-- question -->
        <div class="col-md-12">
          <label for="question" class="form-label">
            {{ $t("contact.question") }}
          </label>
          <textarea
            class="form-control"
            id="question"
            name="question"
            rows="3"
          ></textarea>
        </div>
        <!-- question -->
        <div class="col-12" v-if="$store.state.fav.length > 0">
          <label class="form-label">
            {{ $t("contact.producten") }}
          </label>
          <ul class="list-group">
            <li
              v-for="(item, index) in $store.state.fav"
              v-bind:key="index"
              class="list-group-item d-flex justify-content-between align-items-center py-1"
            >
              {{ item }}
              <AddToFav :currentTile="item" />
            </li>
          </ul>
        </div>
        <div class="col-12 text-center">
          <input type="hidden" id="formWhatProductDoYouWant" value="" />
          <button
            class="btn btn-primary me-3 btn-lg rounded-pill px-5"
            type="submit"
            v-on:click.prevent="submitForm"
          >
            <i class="fas fa-paper-plane"></i>
            <span class="ps-3">{{ $t("contact.send") }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import AddToFav from "@/components/AddToFav.vue";
import axios from "axios";
export default {
  name: "MailForm",
  data() {
    return {
      formSended: false,
    };
  },
  components: {
    AddToFav,
  },
  methods: {
    submitFormTest() {
      alert("submit");
      this.formSended = true;
    },
    submitForm() {
      var form = document.getElementById("contactForm");
      if (form.checkValidity()) {
        var inputs = form.getElementsByTagName("input");
        var req = {};
        for (var index = 0; index < inputs.length; ++index) {
          req[inputs[index].id] = inputs[index].value;
        }
        req["question"] = document.getElementById("question").value;
        req["customerID"] = "";
        req["favs"] = [];
        req["to"] = this.$store.state.email;
        this.$store.state.fav.forEach((value) => {
          req["favs"].push({
            name: value,
          });
        });
        //send mail
        if (req["to"] != "") {
          if (req["to"] == "info@ar-tile.com")
            req["customerID"] = this.$store.state.uid;
          axios
            .post(
              "https://r-devstudio.com/forms/arTileCustomer.php",
              JSON.stringify(req)
            )
            .then(function (response) {
              console.log(response);
              form.reset();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        form.classList.remove("was-validated");
        this.formSended = true;
      } else {
        form.classList.add("was-validated");
      }
    },
  },
};
</script>
