<template>
  <div class="uiblock">
    <div class="center">
      <i class="fas fa-hourglass-half fa-spin"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "Blocker",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.uiblock {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
  opacity: 0.5;
}
.uiblock .center {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
}
</style>
